import React, { createContext, useState, useEffect } from "react";
import { auth } from "./firebase"; // Import the initialized auth instance
import { returnAPI } from "./config"; // Import the function to get the API URL
import { signInWithCustomToken } from "firebase/auth"; // Import the function from Firebase

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState(null); // Add state for the token

  // Function to restore the session manually
  const restoreSession = async () => {
    const refreshToken = localStorage.getItem("refreshToken");

    if (refreshToken) {
      try {
        // Step 1: Use the refresh token to get a new access token
        const tokenResponse = await fetch(`${returnAPI()}/refresh-token`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ refreshToken }),
        });

        if (!tokenResponse.ok) {
          console.error("Failed to refresh the token.");
          setUser(null);
          setLoading(false);
          return;
        }

        const { accessToken } = await tokenResponse.json();
        localStorage.setItem("accessToken", accessToken); // Store the new access token
        setToken(accessToken); // Store the token in state

        // Step 2: Generate a custom token from the access token
        const customTokenResponse = await fetch(
          `${returnAPI()}/generate-custom-token`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ accessToken }),
          }
        );

        if (customTokenResponse.ok) {
          const { customToken } = await customTokenResponse.json();
          await signInWithCustomToken(auth, customToken);

          const firebaseUser = auth.currentUser;
          if (firebaseUser) {
            fetchUser(firebaseUser); // Fetch user data after successful sign-in
          } else {
            setUser(null);
          }
        } else {
          console.error("Failed to generate custom token.");
          setUser(null);
        }
      } catch (error) {
        console.error("Error during session restoration:", error);
        setUser(null);
      }
    } else {
      console.error("No refresh token available.");
      setUser(null);
    }

    setLoading(false);
  };

  // Function to fetch user data from the backend
  const fetchUser = async (firebaseUser) => {
    try {
      const token = await firebaseUser.getIdToken(true); // Ensure the token is fresh
      setToken(token); // Store the token in state

      // Store the refresh token in localStorage
      const refreshToken = firebaseUser.refreshToken;
      localStorage.setItem("refreshToken", refreshToken);

      const response = await fetch(`${returnAPI()}/user`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const userData = await response.json();
        setUser({
          ...userData,
          role: userData.role, // Add role
          package: userData.package, // Add package
        });
        setLoading(false);
      } else {
        setUser(null);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      setUser(null);
    }
  };

  const decrementCredits = (amount) => {
    setUser((prevUser) => {
      if (!prevUser) return prevUser;
      return {
        ...prevUser,
        collectionLimit: prevUser.collectionLimit - amount,
      };
    });
  };

  // Function to refresh the token every 30 minutes
  const startTokenRefreshInterval = () => {
    return setInterval(() => {
      console.log("Refreshing token...");
      restoreSession(); // Call restoreSession every 30 minutes to refresh the token
    }, 30 * 60 * 1000); // 30 minutes in milliseconds
  };

  // Use Firebase's onAuthStateChanged to listen for auth changes
  useEffect(() => {
    console.log("fetchuser0");
    const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
      if (firebaseUser) {
        console.log("User is signed in:", firebaseUser);
        fetchUser(firebaseUser); // Fetch user data if the user is signed in
        const refreshInterval = startTokenRefreshInterval(); // Start refreshing the token
        return () => clearInterval(refreshInterval); // Clear the interval on component unmount
      } else {
        console.log("No user is signed in.");
        restoreSession(); // Attempt to restore the session
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <UserContext.Provider
      value={{ user, loading, token, setToken, decrementCredits, setUser }}
    >
      {children}
    </UserContext.Provider>
  );
};
