import React, { useState, useContext, useEffect } from "react";
import { UserContext } from "./UserContext";
import { auth } from "./firebase";
import { returnAPI } from "./config";
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  signInWithEmailAndPassword,
} from "firebase/auth";

const SettingsPage = () => {
  const { user, token } = useContext(UserContext);
  const [activeTab, setActiveTab] = useState("profile");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ type: "", text: "" });

  // Profile form state
  const [profileData, setProfileData] = useState({
    name: "",
    email: "",
    profilePhoto: null,
  });

  // Security form state
  const [securityData, setSecurityData] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  // Add these near the top with other state declarations
  const [previewUrl, setPreviewUrl] = useState(null);

  // Add this state to track form validity
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  // Add new state for portal URL
  const [portalUrl, setPortalUrl] = useState(null);

  // Add loading state for portal button
  const [portalLoading, setPortalLoading] = useState(false);

  // Load user data into form when component mounts
  useEffect(() => {
    if (user) {
      setProfileData((prev) => ({
        ...prev,
        name: user.name || "",
        email: user.email || "",
      }));
      setPreviewUrl(user.profilePhotoUrl || null);
    }
  }, [user]);

  const handleProfileSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage({ type: "", text: "" });

    try {
      // Create FormData if there's a file to upload
      const formData = new FormData();
      formData.append("name", profileData.name);
      formData.append("email", profileData.email);
      if (profileData.profilePhoto) {
        formData.append("profilePhoto", profileData.profilePhoto);
      }

      const response = await fetch(`${returnAPI()}/user/profile`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (response.ok) {
        setMessage({ type: "success", text: "Profile updated successfully!" });
      } else {
        setMessage({ type: "error", text: "Failed to update profile." });
      }
    } catch (error) {
      setMessage({
        type: "error",
        text: "An error occurred while updating profile.",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSecuritySubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage({ type: "", text: "" });

    if (!isPasswordValid) {
      return;
    }

    try {
      const response = await fetch(`${returnAPI()}/user/password`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          newPassword: securityData.newPassword,
        }),
      });

      if (response.ok) {
        // Reauthenticate the user with the new password
        try {
          const user = auth.currentUser;
          if (user && user.email) {
            await signInWithEmailAndPassword(
              auth,
              user.email,
              securityData.newPassword
            );
            console.log("Successfully reauthenticated user");
          }
        } catch (reauthError) {
          console.error("Reauthentication error:", reauthError);
          setMessage({
            type: "warning",
            text: "Password updated successfully, but you may need to sign in again.",
          });
          return;
        }

        setMessage({ type: "success", text: "Password updated successfully!" });
        setSecurityData({
          newPassword: "",
          confirmPassword: "",
        });
      } else {
        const errorData = await response.json();
        setMessage({
          type: "error",
          text: errorData.message || "Failed to update password.",
        });
      }
    } catch (error) {
      console.error("Password update error:", error);
      setMessage({
        type: "error",
        text: "An error occurred while updating password.",
      });
    } finally {
      setLoading(false);
    }
  };

  // Update the existing handlers
  const handleProfileInputChange = (e) => {
    const { name, value } = e.target;
    setProfileData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSecurityInputChange = (e) => {
    const { name, value } = e.target;
    const newSecurityData = {
      ...securityData,
      [name]: value,
    };
    setSecurityData(newSecurityData);

    // Validate passwords in real-time
    const isLongEnough = newSecurityData.newPassword.length >= 6;
    const doPasswordsMatch =
      newSecurityData.newPassword === newSecurityData.confirmPassword;

    setIsPasswordValid(isLongEnough && doPasswordsMatch);

    // Show validation messages
    if (name === "newPassword" && value.length > 0 && !isLongEnough) {
      setMessage({
        type: "warning",
        text: "Password must be at least 6 characters long.",
      });
    } else if (
      name === "confirmPassword" &&
      value.length > 0 &&
      !doPasswordsMatch
    ) {
      setMessage({ type: "warning", text: "Passwords do not match." });
    } else {
      setMessage({ type: "", text: "" });
    }
  };

  // Add this with other handlers
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfileData((prev) => ({
        ...prev,
        profilePhoto: file,
      }));

      // Create preview URL for the image
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // Add function to get portal URL
  const getPortalUrl = async () => {
    try {
      const response = await fetch(`${returnAPI()}/create-portal-session`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const { url } = await response.json();
        return url;
      }
    } catch (error) {
      console.error("Error getting portal URL:", error);
    }
    return null;
  };

  // Update the portal click handler
  const handlePortalClick = async (e) => {
    if (!user?.plan) return; // If no plan, let the default href="/pricing" work

    e.preventDefault();
    setPortalLoading(true);

    try {
      const response = await fetch(`${returnAPI()}/create-portal-session`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      console.log(response.ok, "response.ok");
      if (response.ok) {
        const { url } = await response.json();
        window.location.href = url;
      } else {
        const errorData = await response.json();
        if (
          errorData.message?.includes("No Stripe customer found for this user")
        ) {
          console.log(errorData, "response.ok2");

          setMessage({
            type: "warning",
            text: "Stripe billing portal is only available if an active payment method was used to make the payment. This might happen if you used a 100% discount code.",
          });
        } else {
          setMessage({
            type: "error",
            text:
              errorData.message ||
              "Failed to connect to billing portal. Please try again later.",
          });
        }
      }
    } catch (error) {
      console.error("Error getting portal URL:", error);
      setMessage({
        type: "error",
        text: "Failed to connect to billing portal. Please try again later.",
      });
    } finally {
      setPortalLoading(false);
    }
  };

  // Update the tab switching function to clear messages
  const handleTabChange = (tab) => {
    setActiveTab(tab.toLowerCase());
    setMessage({ type: "", text: "" }); // Clear any existing messages
  };

  return (
    <div className="min-h-screen bg-gray-50 p-8">
      <div className="max-w-3xl mx-auto">
        {/* Header */}
        <div className="mb-8 text-left">
          <h1 className="text-3xl font-bold text-gray-900 text-left">
            Account Settings
          </h1>
          <p className="text-gray-500 mt-2 text-left">
            Manage your account preferences and settings
          </p>
        </div>

        {/* Tabs */}
        <div className="mb-8 bg-white rounded-lg shadow">
          <div className="flex border-b">
            {["Profile", "Security", "Billing"].map((tab) => (
              <button
                key={tab}
                onClick={() => handleTabChange(tab)}
                className={`px-6 py-4 text-sm font-medium ${
                  activeTab === tab.toLowerCase()
                    ? "border-b-2 border-blue-500 text-blue-600"
                    : "text-gray-500 hover:text-gray-700"
                }`}
              >
                {tab}
              </button>
            ))}
          </div>
        </div>

        {/* Content */}
        <div className="bg-white rounded-lg shadow p-6">
          {/* Profile Tab */}
          {activeTab === "profile" && (
            <form onSubmit={handleProfileSubmit} className="space-y-6">
              <div className="text-left">
                <h3 className="text-lg font-medium text-gray-900">
                  Profile Information
                </h3>
                <p className="text-sm text-gray-500 mt-1">
                  Update your personal information and photo
                </p>
              </div>

              {/* Profile Photo */}
              <div className="flex flex-col sm:flex-row items-center gap-6">
                <div className="w-20 h-20 rounded-full bg-gray-100 border-2 border-gray-200 flex items-center justify-center overflow-hidden">
                  {previewUrl || user?.profilePhotoUrl ? (
                    <img
                      src={previewUrl || user?.profilePhotoUrl}
                      alt="Profile"
                      className="w-full h-full object-cover"
                    />
                  ) : (
                    <div className="text-gray-400">
                      <svg
                        className="w-8 h-8"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                        />
                      </svg>
                    </div>
                  )}
                </div>
                <div>
                  <input
                    type="file"
                    onChange={handleFileChange}
                    className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
                  />
                </div>
              </div>

              {/* Form Fields */}
              <div className="grid grid-cols-1 gap-6">
                <div className="text-left">
                  <label className="block text-sm font-medium text-gray-700">
                    Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={profileData.name}
                    onChange={handleProfileInputChange}
                    className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 text-gray-900 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                  />
                </div>
                <div className="text-left">
                  <label className="block text-sm font-medium text-gray-700">
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={profileData.email}
                    onChange={handleProfileInputChange}
                    readOnly
                    className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 text-gray-900 bg-gray-50 cursor-not-allowed"
                  />
                </div>
              </div>

              {message.text && (
                <div
                  className={`text-${
                    message.type === "error" ? "red" : "green"
                  }-600 text-sm`}
                >
                  {message.text}
                </div>
              )}

              <div className="flex justify-end gap-4">
                <button
                  type="submit"
                  disabled={loading}
                  className="px-4 py-2 bg-blue-600 text-white rounded-md text-sm font-medium hover:bg-blue-700 disabled:opacity-50"
                >
                  {loading ? "Saving..." : "Save Changes"}
                </button>
              </div>
            </form>
          )}

          {/* Security Tab */}
          {activeTab === "security" && (
            <form
              onSubmit={handleSecuritySubmit}
              className="space-y-6 text-left"
            >
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    New Password
                  </label>
                  <input
                    type="password"
                    name="newPassword"
                    value={securityData.newPassword}
                    onChange={handleSecurityInputChange}
                    className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 text-gray-900 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                    minLength="6"
                  />
                  <p className="mt-1 text-sm text-gray-500">
                    Password must be at least 6 characters long
                  </p>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    name="confirmPassword"
                    value={securityData.confirmPassword}
                    onChange={handleSecurityInputChange}
                    className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 text-gray-900 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                  />
                </div>
              </div>

              {message.text && (
                <div
                  className={`text-sm ${
                    message.type === "error"
                      ? "text-red-600"
                      : message.type === "warning"
                      ? "text-amber-600"
                      : message.type === "success"
                      ? "text-green-600"
                      : ""
                  }`}
                >
                  {message.text}
                </div>
              )}

              <div className="flex justify-end gap-4">
                <button
                  type="submit"
                  disabled={
                    loading || !isPasswordValid || !securityData.newPassword
                  }
                  className={`px-4 py-2 text-white rounded-md text-sm font-medium 
                    ${
                      loading || !isPasswordValid || !securityData.newPassword
                        ? "bg-gray-400 cursor-not-allowed"
                        : "bg-blue-600 hover:bg-blue-700"
                    }`}
                >
                  {loading ? "Updating..." : "Update Password"}
                </button>
              </div>
            </form>
          )}

          {/* Billing Tab */}
          {activeTab === "billing" && (
            <div className="space-y-6 text-left">
              <div>
                <h3 className="text-lg font-medium text-gray-900">
                  Billing Settings
                </h3>
                <p className="text-sm text-gray-500 mt-1">
                  Manage your subscription and payments through Stripe
                </p>
              </div>

              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Current Plan
                  </label>
                  <div className="mt-1 block w-full !rounded-md !border !border-gray-300 px-3 py-2 !text-gray-900">
                    {user?.plan || "Free Plan"}
                  </div>
                </div>

                <div>
                  <a
                    href={user?.plan ? "#" : "/pricing"}
                    onClick={handlePortalClick}
                    className={`inline-flex items-center px-4 py-2 bg-blue-600 !text-white rounded-md text-sm font-medium 
                      ${
                        portalLoading
                          ? "opacity-50 cursor-not-allowed"
                          : "hover:bg-blue-700"
                      }`}
                    disabled={portalLoading}
                  >
                    {portalLoading ? (
                      <>
                        <svg
                          className="animate-spin -ml-1 mr-3 h-4 w-4 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          />
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          />
                        </svg>
                        Loading...
                      </>
                    ) : (
                      <>
                        {user?.plan
                          ? "Manage Subscription in Stripe"
                          : "Upgrade Your Plan"}
                        <svg
                          className="ml-2 -mr-1 h-4 w-4"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </>
                    )}
                  </a>
                </div>

                {message.text && (
                  <div
                    className={`text-sm ${
                      message.type === "error"
                        ? "text-red-600"
                        : message.type === "warning"
                        ? "text-amber-600"
                        : "text-green-600"
                    }`}
                  >
                    {message.text}
                  </div>
                )}

                <p className="text-sm text-gray-500">
                  {user?.plan
                    ? "All payments are securely processed by Stripe. You can manage your billing information, view invoices, and update your subscription through the Stripe customer portal."
                    : "Upgrade your plan to access premium features and increase your usage limits."}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SettingsPage;
