import React, { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";
import { nanoid } from "nanoid"; // For generating unique IDs
import { useLocation, useParams } from "react-router-dom";
import { returnAPI, returnCacheURL } from "./config";
import { UserContext } from "./UserContext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const masterParentId = "Lx2VSv07LplHyGPpI7tt";
export const DataContext = createContext();

// const backendURL = "https://propromptstore-api.codedesign.ai";
const axiosInstance = axios.create();

const backendURL = returnAPI();
const cacheURL = returnCacheURL();
export const DataProvider = ({ children }) => {
  const { token, setToken } = useContext(UserContext);

  const [data, setData] = useState({ collections: [], prompts: [] });
  const [origin, setOrigin] = useState(null);
  const [fetchingAdditionalPrompts, setIsFetchingAdditionalPrompts] =
    useState(false);

  const [fetchedParentId, setFetchedParentId] = useState(null);
  const location = useLocation();
  const { parentId } = useParams();
  console.log(useParams(), "testingjoka");

  // Flag to prevent multiple refresh attempts
  let isRefreshing = false;
  let failedQueue = [];
  let activeRequests = new Set();

  const processQueue = (error, token = null) => {
    failedQueue.forEach((prom) => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });

    failedQueue = [];
  };

  const getAccessToken = () => localStorage.getItem("accessToken");
  const getRefreshToken = () => localStorage.getItem("refreshToken");

  const setTokens = (accessToken, refreshToken) => {
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("refreshToken", refreshToken);
  };

  const refreshAccessToken = async () => {
    const refreshToken = getRefreshToken();
    try {
      const response = await axiosInstance.post(`${backendURL}/refresh-token`, {
        refreshToken,
      });
      const { accessToken } = response.data;
      setTokens(accessToken, refreshToken);
      return accessToken;
    } catch (error) {
      console.error("Error refreshing access token:", error);
      // Handle token refresh failure (e.g., redirect to login)
      return null;
    }
  };

  // Axios Interceptors for Token Handling and Toast Notifications
  useEffect(() => {
    axiosInstance.interceptors.request.use(
      (config) => {
        const currentToken = getAccessToken();
        if (currentToken) {
          config.headers["Authorization"] = `Bearer ${currentToken}`;
        }

        // Skip toasts for GET requests
        if (config.method.toLowerCase() === "get") return config;

        const requestKey = `${config.method}-${config.url}`;
        if (!activeRequests.has(requestKey)) {
          activeRequests.add(requestKey);
          toast.info("Request is pending...", {
            toastId: requestKey,
            autoClose: 500,
          });
        }

        return config;
      },
      (error) => {
        const requestKey = `${error.config.method}-${error.config.url}`;
        if (activeRequests.has(requestKey)) {
          toast.error("Request failed!", {
            toastId: `${requestKey}-fail`,
            autoClose: 5000,
          }); // Show failure toast
          activeRequests.delete(requestKey);
        }
        return Promise.reject(error);
      }
    );

    axiosInstance.interceptors.response.use(
      (response) => {
        const { config } = response;
        const requestKey = `${config.method}-${config.url}`;

        if (config.method.toLowerCase() !== "get") {
          toast.success("Request successful!", {
            toastId: `${requestKey}-success`,
            autoClose: 2000,
          });
        }

        activeRequests.delete(requestKey);
        return response;
      },
      async (error) => {
        const originalRequest = error.config;
        const requestKey = `${originalRequest.method}-${originalRequest.url}`;
        if (originalRequest.method.toLowerCase() === "get") {
          return Promise.reject(error); // Skip toasts for GET requests
        }

        if (
          error.response &&
          error.response.status === 401 &&
          !originalRequest._retry
        ) {
          if (isRefreshing) {
            return new Promise((resolve, reject) => {
              failedQueue.push({ resolve, reject });
            })
              .then((newToken) => {
                originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
                return axiosInstance(originalRequest);
              })
              .catch((err) => {
                toast.error("Request failed!", {
                  toastId: `${requestKey}-fail`,
                  autoClose: 5000,
                });
                activeRequests.delete(requestKey);
                return Promise.reject(err);
              });
          }

          originalRequest._retry = true;
          isRefreshing = true;

          try {
            const newToken = await refreshAccessToken();
            if (newToken) {
              setToken(newToken);
              originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
              processQueue(null, newToken);
              toast.success("Request successful after refresh!", {
                toastId: requestKey,
                autoClose: 2000,
              });
              activeRequests.delete(requestKey);
              return axiosInstance(originalRequest);
            } else {
              processQueue(error, null);
              toast.error("Failed to refresh token!", {
                toastId: requestKey,
                autoClose: 5000,
              });
              return Promise.reject(error);
            }
          } catch (err) {
            processQueue(err, null);
            toast.error("Failed to refresh token!", {
              toastId: requestKey,
              autoClose: 5000,
            });
            return Promise.reject(err);
          } finally {
            isRefreshing = false;
          }
        }

        toast.error("Request failed!", {
          toastId: `${requestKey}-fail`,
          autoClose: 5000,
        });
        activeRequests.delete(requestKey);
        return Promise.reject(error);
      }
    );

    return () => {
      axiosInstance.interceptors.request.eject();
      axiosInstance.interceptors.response.eject();
    };
  }, [token]);

  useEffect(() => {
    // Attach the Authorization header dynamically with the latest token
    axiosInstance.interceptors.request.use(
      (config) => {
        const latestToken = token; // Dynamically access the latest token

        if (latestToken) {
          config.headers["Authorization"] = `Bearer ${latestToken}`;
        }

        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    // Cleanup interceptor on unmount
    return () => {
      axiosInstance.interceptors.request.eject();
    };
  }, [token]);

  useEffect(() => {
    // Determine origin based on URL
    const path = window.location.pathname.split("/");
    console.log(path, "testingjoke323");
    if (path.includes("master")) {
      setOrigin("master");
    } else if (path.includes("prompts")) {
      setOrigin("prompts");
    } else if (path.includes("services")) {
      setOrigin("services");
    } else {
      setOrigin(null);
    }

    console.log("Component mounted, fetching all collections and prompts");

    // Fetch all collections and prompts when the component is mounted
    axiosInstance
      .get(`${cacheURL}/all-collections`)
      .then((response) => {
        const { collections, prompts } = response.data;

        // Store all collections and prompts separately
        console.log(collections, "tesss");
        setData((prevData) => ({
          ...prevData,
          allCollections: collections,
          allPrompts: prompts,
        }));
      })
      .catch((error) => {
        console.error("Error fetching all collections and prompts:", error);
      });
  }, []);
  useEffect(() => {
    // Determine origin based on URL
    const path = window.location.pathname.split("/");
    let localOrigin = null;
    if (path.includes("master")) {
      localOrigin = "master";
      setOrigin("master");
    } else if (path.includes("prompts")) {
      localOrigin = "prompts";
      setOrigin("prompts");
    } else if (path.includes("services")) {
      localOrigin = "services";
      setOrigin("services");
    } else {
      setOrigin(null);
    }
    console.log(
      "testingjoke",
      parentId,
      fetchedParentId,
      origin,
      localOrigin,
      path
    );

    if (parentId !== fetchedParentId) {
      const isPrompts =
        window.location.pathname === "/prompts/" ||
        window.location.pathname === "/prompts";
      console.log(isPrompts, origin, "testingjoke33");
      setIsFetchingAdditionalPrompts(true);
      const dynamicUrl =
        localOrigin === "prompts" && parentId !== "my-prompts"
          ? cacheURL
          : backendURL;

      axiosInstance
        .get(`${dynamicUrl}/all`, {
          params: {
            parentId: isPrompts
              ? "Lx2VSv07LplHyGPpI7tt"
              : localOrigin === "services"
              ? "proprompt_store_services"
              : parentId,
            origin: localOrigin,
          },
        })
        .then((response) => {
          setData((prevData) => {
            const mergedCollections = [
              ...prevData.collections,
              ...response.data.collections,
            ];
            const mergedPrompts = [
              ...prevData.prompts,
              ...response.data.prompts,
            ];

            // Remove duplicates
            const uniqueCollections = mergedCollections.filter(
              (v, i, a) => a.findIndex((t) => t.id === v.id) === i
            );
            const uniquePrompts = mergedPrompts.filter(
              (v, i, a) => a.findIndex((t) => t.id === v.id) === i
            );

            return {
              ...prevData,
              collections: uniqueCollections,
              prompts: uniquePrompts,
            };
          });
          setFetchedParentId(parentId);
          setIsFetchingAdditionalPrompts(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setIsFetchingAdditionalPrompts(false);
        });
    }
  }, [parentId, fetchedParentId, location]);

  const fetchCollectionsByParentId = async (parentId) => {
    if (parentId !== fetchedParentId) {
      setIsFetchingAdditionalPrompts(true);
      try {
        const dynamicUrl =
          origin === "prompts" && parentId !== "my-prompts"
            ? cacheURL
            : backendURL;

        const response = await axiosInstance.get(`${dynamicUrl}/all`, {
          params: { parentId, origin: origin },
        });
        setData((prevData) => {
          const mergedCollections = [
            ...prevData.collections,
            ...response.data.collections,
          ];
          const mergedPrompts = [...prevData.prompts, ...response.data.prompts];

          // Remove duplicates
          const uniqueCollections = mergedCollections.filter(
            (v, i, a) => a.findIndex((t) => t.id === v.id) === i
          );
          const uniquePrompts = mergedPrompts.filter(
            (v, i, a) => a.findIndex((t) => t.id === v.id) === i
          );

          return {
            collections: uniqueCollections,
            prompts: uniquePrompts,
          };
        });
        setFetchedParentId(parentId);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsFetchingAdditionalPrompts(false);
      }
    }
  };

  const addCollection = (newCollection) => {
    setData((prevData) => ({
      ...prevData,
      collections: [...prevData.collections, newCollection],
    }));
  };

  const addPrompt = (newPrompt) => {
    setData((prevData) => ({
      ...prevData,
      prompts: [...prevData.prompts, newPrompt],
    }));
  };

  const removeCollection = (id) => {
    setData((prevData) => ({
      ...prevData,
      collections: prevData.collections.filter(
        (collection) => collection.id !== id
      ),
    }));
  };

  const removePrompt = (id) => {
    setData((prevData) => ({
      ...prevData,
      prompts: prevData.prompts.filter((prompt) => prompt.id !== id),
    }));
  };

  const updateCollection = (updatedCollection) => {
    setData((prevData) => ({
      ...prevData,
      collections: prevData.collections.map((collection) =>
        collection.id === updatedCollection.id ? updatedCollection : collection
      ),
    }));
  };

  const updatePrompt = (updatedPrompt) => {
    setData((prevData) => ({
      ...prevData,
      prompts: prevData.prompts.map((prompt) =>
        prompt.id === updatedPrompt.id ? updatedPrompt : prompt
      ),
    }));
  };

  const handleDelete = async (item) => {
    const type = item.collectionId !== undefined ? "prompts" : "collections";
    const { id } = item;

    try {
      const response = await axiosInstance.delete(
        `${backendURL}/${type}/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        if (type === "prompts") {
          removePrompt(id);
        } else if (type === "collections") {
          removeCollection(id);
        }
        console.log(`${type.slice(0, -1)} deleted successfully`);
      } else {
        console.error(`Failed to delete ${type.slice(0, -1)}`);
      }
    } catch (error) {
      console.error(`Error deleting ${type.slice(0, -1)}:`, error);
    }
  };

  const handleUpdate = async (item) => {
    const type = item.collectionId !== undefined ? "prompts" : "collections";
    const { id } = item;

    try {
      const response = await axiosInstance.put(
        `${backendURL}/${type}/${id}`,
        item
      );

      if (response.status === 200) {
        if (type === "prompts") {
          updatePrompt(item);
        } else if (type === "collections") {
          updateCollection(item);
        }
        console.log(`${type.slice(0, -1)} updated successfully`);
      } else {
        console.error(`Failed to update ${type.slice(0, -1)}`);
      }
    } catch (error) {
      console.error(`Error updating ${type.slice(0, -1)}:`, error);
    }
  };
  const handleAdd = async (item) => {
    const type = item.collectionId !== undefined ? "prompts" : "collections";

    try {
      const response = await axiosInstance.post(`${backendURL}/${type}`, item, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200 || response.status === 201) {
        if (type === "prompts") {
          addPrompt(item);
        } else if (type === "collections") {
          addCollection(item);
        }
        console.log(`${type.slice(0, -1)} added successfully`);
      } else {
        console.error(`Failed to add ${type.slice(0, -1)}`);
      }
    } catch (error) {
      console.error(`Error adding ${type.slice(0, -1)}:`, error);
    }
  };

  const handleExport = async (item) => {
    try {
      const response = await fetch(`${backendURL}/export?id=${item}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        // Get the content type to determine the file type (PDF or ZIP)
        const contentType = response.headers.get("content-type");
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        // Determine the correct file extension based on the content type
        let fileExtension = "zip"; // Default to zip
        if (contentType.includes("application/pdf")) {
          fileExtension = "pdf";
        }

        const link = document.createElement("a");
        link.href = url;
        link.download = `exported_item.${fileExtension}`; // Use the correct file extension
        document.body.appendChild(link);
        link.click();
        link.remove();

        console.log(`Item exported successfully as ${fileExtension}`);
      } else {
        console.error(`Failed to export item`);
      }
    } catch (error) {
      console.error(`Error exporting item:`, error);
    }
  };

  const handleDuplicate = async (item) => {
    const type = item.collectionId !== undefined ? "prompts" : "collections";
    let duplicatedItem = { ...item, duplicate: true };
    if (type === "prompts") {
      duplicatedItem = { ...item, id: nanoid(), duplicate: true };
    }
    try {
      const response = await axiosInstance.post(
        `${backendURL}/${type}`,
        duplicatedItem,

        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        console.log(response.data, "testingbit");
        // Axios considers 201 as success for POST
        if (type === "prompts") {
          addPrompt(duplicatedItem);
        } else if (type === "collections") {
          addCollection({ ...duplicatedItem, id: response.data.id });
        }
        console.log(`${type.slice(0, -1)} duplicated successfully`);
      } else {
        console.error(`Failed to duplicate ${type.slice(0, -1)}`);
      }
    } catch (error) {
      console.error(`Error duplicating ${type.slice(0, -1)}:`, error);
    }
  };

  const handleUpdateOrder = async (parentId, newOrder, type) => {
    try {
      await axiosInstance.post(`${backendURL}/update-order`, {
        parentId,
        newOrder,
        type,
      });

      // Update the local state with the new order
      setData((prevData) => {
        if (type === "collections") {
          const reorderedCollections = newOrder
            .map((id) =>
              prevData.collections.find((collection) => collection.id === id)
            )
            .filter((collection) => collection !== undefined);

          // Append collections not included in the newOrder to maintain the rest of the state
          const otherCollections = prevData.collections.filter(
            (collection) => !newOrder.includes(collection.id)
          );

          return {
            ...prevData,
            collections: [...reorderedCollections, ...otherCollections],
          };
        } else if (type === "prompts") {
          const reorderedPrompts = newOrder
            .map((id) => prevData.prompts.find((prompt) => prompt.id === id))
            .filter((prompt) => prompt !== undefined);

          // Append prompts not included in the newOrder to maintain the rest of the state
          const otherPrompts = prevData.prompts.filter(
            (prompt) => !newOrder.includes(prompt.id)
          );

          return {
            ...prevData,
            prompts: [...reorderedPrompts, ...otherPrompts],
          };
        } else {
          return prevData;
        }
      });
    } catch (error) {
      console.error(
        `Error updating ${type} order for parentId ${parentId}:`,
        error
      );
    }
  };

  const submitSelectedCollections = async (selectedCollectionIds) => {
    try {
      const response = await axiosInstance.post(
        `${backendURL}/update-user-collections`,
        {
          collectionIds: selectedCollectionIds,
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error submitting collections:", error);
      throw error;
    }
  };

  return (
    <DataContext.Provider
      value={{
        data,
        addCollection,
        addPrompt,
        handleDelete,
        handleUpdate,
        handleAdd,
        handleDuplicate,
        handleUpdateOrder,
        handleExport,
        fetchingAdditionalPrompts,
        fetchCollectionsByParentId,
        submitSelectedCollections,
        origin,
        masterParentId,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
