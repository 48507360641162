import React, { useState, useEffect, useContext } from "react";
import "./CreditsPopup.css";
import { useParams, useLocation } from "react-router-dom";
import { DataContext } from "./DataContext"; // Assuming DataContext is defined elsewhere
import { UserContext } from "./UserContext"; // Assuming DataContext is defined elsewhere

import { ReactComponent as DollarButton } from "./svg/dollar.svg";

const Popup = ({ isOpen, onClose, creditsLeft }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [warning, setWarning] = useState("");
  const { parentId } = useParams();
  const location = useLocation();
  const { user, decrementCredits } = useContext(UserContext); // Get decrementCredits from UserContext

  const {
    data: { collections },
    fetchCollectionsByParentId,
    submitSelectedCollections,
  } = useContext(DataContext);

  useEffect(() => {
    if (isOpen) {
      // Get collections with the specific parentId
      const collectionsWithParentId = collections.filter(
        (collection) => collection.parentId === "Lx2VSv07LplHyGPpI7tt"
      );

      // Make API call if there are less than 2 collections with this parentId
      if (collectionsWithParentId.length < 2) {
        fetchCollectionsByParentId("Lx2VSv07LplHyGPpI7tt");
      }
    }
  }, [isOpen, collections, fetchCollectionsByParentId]);

  const filterCollections = (collections, term) => {
    return collections.filter((collection) =>
      collection.name.toLowerCase().includes(term.toLowerCase())
    );
  };

  const getCollectionsByParentId = (collections = [], parentId) => {
    return collections.filter((collection) => collection.parentId === parentId);
  };

  let collectionsToRender = [];

  collectionsToRender = getCollectionsByParentId(
    collections,
    "Lx2VSv07LplHyGPpI7tt"
  );

  const filteredCollections = filterCollections(
    collectionsToRender,
    searchQuery
  );

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleItemCheck = (itemId) => {
    if (selectedItems.includes(itemId)) {
      setSelectedItems(selectedItems.filter((i) => i !== itemId));
      setWarning(""); // Remove warning if items are deselected
    } else if (selectedItems.length < creditsLeft) {
      setSelectedItems([...selectedItems, itemId]);
    } else {
      setWarning("You cannot select more items than your available credits.");
    }
  };

  const handleSubmit = async () => {
    try {
      const result = await submitSelectedCollections(selectedItems);
      decrementCredits(selectedItems.length);
      console.log("Submission successful:", result);
      // Handle success, show a message, close the popup, etc.
      onClose();
    } catch (error) {
      console.error("Submission failed:", error);
      // Handle failure, show an error message, etc.
    }
  };

  const renderItems = (items) => {
    return items.map((item) => (
      <li key={item.id}>
        <label className="item-label">
          <input
            type="checkbox"
            checked={selectedItems.includes(item.id)}
            onChange={() => handleItemCheck(item.id)}
          />
          {item.name}
        </label>
      </li>
    ));
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  if (!isOpen) return null;

  return (
    <div className="popup-overlay" onClick={handleOverlayClick}>
      <div
        className="popup-container"
        style={{
          overflow: "hidden",
          maxHeight: "90vh", // Limit height to 90% of viewport
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div className="popup-header">
          <h2>Credits Balance Information</h2>
          <button className="close-button" onClick={onClose}>
            &times;
          </button>
        </div>
        <div
          className="popup-content"
          style={{
            overflowY: "auto",
            flex: 1,
            paddingBottom: "20px", // Add some padding at the bottom
          }}
        ></div>
        <div className="credit-info-section">
          <div className="credit-info-card">
            <p>Primary Credit Balance</p>
            <div className="credit-info-balance">
              <DollarButton />
              <span>{creditsLeft} credits available</span>
            </div>
            <a href="/buy-credits" className="buy-credits-link">
              Buy Additional Credits
            </a>
          </div>
        </div>

        <div className="popup-search-section">
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchChange}
            className="search-input"
          />
          <ul className="item-list">
            {renderItems(filteredCollections)}
            {/* Rendering filtered collections */}
          </ul>
        </div>
        {warning && <div className="warning-message">{warning}</div>}
        <div
          className="popup-buttons"
          style={{
            padding: "16px",
            backgroundColor: "white",
            borderTop: "1px solid #eee",
            position: "sticky",
            bottom: 0,
            textAlign: "center",
          }}
        >
          <button
            className="save-button"
            onClick={handleSubmit}
            disabled={selectedItems.length > creditsLeft}
            style={{
              backgroundColor: "#4CAF50",
              color: "white",
              padding: "12px 24px",
              border: "none",
              borderRadius: "4px",
              fontSize: "16px",
              cursor: "pointer",
              width: "100%",
              display: "inline-block",
            }}
          >
            Save & Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default Popup;
